import React, { useEffect, useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { DashboardTravelData, TravelDayData } from "../types";
import { getCurrentDate } from "../utils";

export interface DashboardTravelItemData {
    /**
     * travel item
     */
    travel: DashboardTravelData;

    /**
     * Date selected in calendar
     */
    calendarSelectedDate: string;

    /**
     * wWhen received itinerary shows only next day
     */

    showOnlyNextDay?: boolean;
}

const DashboardTravelItinerary: React.FC<DashboardTravelItemData> = ({
    travel,
    calendarSelectedDate,
    showOnlyNextDay,
}) => {
    const [currentDayInItinerary, setCurrentDayInItinerary] = useState<number>(0);
    const globalItinerary: TravelDayData[] = [];
    travel.reservation.itinerary.map((itineraryItem) =>
        itineraryItem.days.map((day: TravelDayData) => globalItinerary.push(day))
    );

    const currentItineraryDayCalculator = () => {
        const oneDay = 24 * 60 * 60 * 1000;
        const departureDate = new Date(travel.reservation.travel_start_date).getTime();
        const todayDate = new Date(getCurrentDate(calendarSelectedDate, null, null)).getTime();
        setCurrentDayInItinerary(Math.round(Math.abs((departureDate - todayDate) / oneDay)));
    };

    useEffect(() => {
        currentItineraryDayCalculator(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarSelectedDate]);

    return showOnlyNextDay ? (
        <div className="flex flex-wrap text-grey-dark-3 text-xs mt-2">
            <span>
                <span
                    title={`${globalItinerary[currentDayInItinerary]?.location?.name || "Lugar sin asignar"}, ${
                        globalItinerary[currentDayInItinerary]?.country?.name
                    }`}
                    className=" text-yellow-dark-1"
                >
                    {`${globalItinerary[currentDayInItinerary]?.location?.name || "Lugar sin asignar"},
                        ${globalItinerary[currentDayInItinerary]?.country?.name || "País sin asignar"}`}
                </span>

                <span className="px-1">
                    <DoubleArrowIcon className="text-grey-light-1 relative bottom-[1px]" sx={{ fontSize: "12px" }} />
                </span>
                <span
                    title={`${globalItinerary[currentDayInItinerary + 1]?.location?.name || "Lugar sin asignar"}, 
                    ${globalItinerary[currentDayInItinerary + 1]?.country?.name || "País sin asignar"}`}
                >
                    {globalItinerary[currentDayInItinerary + 1]?.location?.name ||
                        globalItinerary[currentDayInItinerary + 1]?.country?.name ||
                        "Lugar sin asignar"}
                </span>
            </span>
        </div>
    ) : (
        <div className="flex flex-wrap text-grey-dark-3 text-xs mt-2">
            {globalItinerary.map((day, ind) => (
                <span key={day.id}>
                    <span
                        title={`${day.location?.name || "Lugar sin asignar"}, ${day?.country?.name}`}
                        className={`${ind === currentDayInItinerary ? " text-yellow-dark-1" : ""}`}
                    >
                        {day.location?.name || day.country?.name}
                    </span>
                    {globalItinerary.length !== ind + 1 && (
                        <span className="px-1">
                            <DoubleArrowIcon
                                className="text-grey-light-1 relative bottom-[1px]"
                                sx={{ fontSize: "12px" }}
                            />
                        </span>
                    )}
                </span>
            ))}
        </div>
    );
};

export default DashboardTravelItinerary;
